
  import { mapGetters } from 'vuex'

  export default {
    name: `SummaryStep`,
    components: {},
    computed: {
      ...mapGetters({
        currentCompany: `companies/getCurrentCompanyInfos`,
        totalCredit: `marketplace/totalCredits`,
        selectedChecks: `marketplace/selectedChecks`,
        selectedJobLocation: `marketplace/selectedJobLocation`,
        selectedReason: `marketplace/selectedReason`,
        selectedDossier: `marketplace/selectedDossier`,
        selectedCategory: `marketplace/selectedCategory`,
        candidateForm: `marketplace/candidateForm`,
        candidateFormsAnswers: `marketplace/candidateFormsAnswers`,
        countries: `formData/countries`
      }),
      candidateInfos() {
        const ordered = this.$helpers.sortElements(this.candidateForm, (a, b) => a[1].order - b[1].order)
        return Object.values(ordered).filter((value) => value.is_question && this.$shared.isDisplayed(value, this.candidateFormsAnswers))
      }
    },
    methods: {
      getText(fElement) {
        const aForm = this.candidateForm[fElement.title]
        const aValue = this.candidateFormsAnswers[fElement.title]
        switch (fElement.type) {
          case `selection_controls`:
          case `select`:
            return aForm?.answers_list.filter(i => i.value === aValue)[0]?.text
          case `country`: {
            if (aForm.options.multiple.value) {
              const value = aValue ?? []
              return  this.countries.filter(i => value.includes(i.value)).map(i => i.text).join(`, `)
            } else {
              return this.countries.filter(i => i.value === aValue)[0]?.text
            }
          }
          case `document`:
            return aValue?.new.map(d => d.name).join(`<br>`)
          case `da_signer`:
            return aValue?.new.map(d => d.name).join(`<br>`)
          case `date`:
            return  !!aValue ? this.$moment(aValue, `YYYY-MM-DD`).format(`DD.MM.YYYY`): ``
          case `radio`:
            break
          case `input_searcher`:
            return aValue?.name;
          default:
            return aValue
        }
      }
    }
  }
